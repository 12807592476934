import React from 'react';
import logo from '../commute_logo_full.svg';

export default function MobileAlert({onClose, isMobile}) {
  if(window.location.hash == '#/trip-recording/nonQrChecking' || window.location.hash == '#/trip-recording' ){
    return null;
  }
  return (
    <div className="mobile-alert">
      <img src={logo} alt="Logo" className="branding" />
         <p>Download our mobile app for a better experience</p>
         <div className="btn-container">
            {
                isMobile.isAndroid ?
                      <a
                          className="button primary"
                         // href="intent://#Intent;scheme=commutewithenterprise;package=com.commutewithenterprise.mobile;end"
                           href="https://play.google.com/store/apps/details?id=com.commutewithenterprise.mobile"
                          rel="noopener noreferrer"
                      >
                          <span className="smartbanner-button-text">Download App</span>
                      </a>
              :
                      <a
                         // href="itmss://itunes.apple.com/us/app/id1440331154"
                          href="https://apps.apple.com/us/app/commute-with-enterprise/id1440331154"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button primary"
                      >
                          <span className="smartbanner__button__label">Download App</span>
                      </a>
            }
          <button  className="button secondary" onClick={onClose}>Continue on Browser</button>
         </div>
    </div>
  );
}
