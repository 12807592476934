import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as actionTypes from './redux/actionTypes';
import PrivateRoute from './shared/PrivateRoute';
import Login from './login/Login';
import PasswordReset from './login/PasswordReset';
import Banner from './shared/banner/Banner';
import MetaTags from 'react-meta-tags';
import Header from './shared/header/Header';
import Footer from './shared/footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import routes from './routes';
import loginRedirectService from './login/loginRedirectService';
import config from './appConfig';
import UserService from './shared/user-service';
import logger from './shared/logger';
import analyticsService from './shared/analytics/analytics-service';
import Error from './login/ErrorPage';
import azureService  from './login/azureService';
import localStorageService from './shared/localStorageService';
import { parseQueryParams, checkForDeeplink, isMobile} from './shared/helpers/redirectHelpers';
import MobileAlert from './login/mobileAlert';
import ErrorScreen from './shared/components/error-screen/ErrorScreen';
const googleMapsURL = `https://maps.googleapis.com/maps/api/js?${config.googleMapsCredentials}&libraries=places,geometry,drawing`;
export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state.unlisten = this.props.history.listen(this.props.updateHistory);
     azureService.createAzureInstance();
      this.isMobileDevice = isMobile();
      this.isMobileBrowser = localStorage.getItem('mobileBrowser');
    //  this.preferredMobile = localStorage.getItem('PreferedMobile');
      this.continueFlow = !this.isMobileDevice  || ( this.isMobileDevice && this.isMobileBrowser);
  }

  componentDidMount() {
    const scriptjs = require('scriptjs');
    scriptjs(googleMapsURL);
    var page = this.props.location.pathname.substr(1) || '';
    var deeplink;
    if(page) {
      var link = localStorage.getItem('mobileDeepLink') && localStorage.getItem('mobileDeepLink').replace("/","") ;
      if(link){
         deeplink = checkForDeeplink(link);
      }else {
         deeplink = checkForDeeplink(page);
      }
      deeplink ?  localStorageService.setItem('deeplink', deeplink) : null;
    }
   
    if(this.continueFlow) {

      if(this.props.location.pathname.startsWith('/joinavanpool')){
        var paramObj = parseQueryParams(this.props.location.search);
        var inviteId = paramObj.inviteid;
        localStorage.clear();
        localStorageService.setItem('inviteId', inviteId);
        azureService.signUpROSParticipant(inviteId);
      };

      if(this.props.location.pathname.startsWith('/emailverification')) {
        var paramObj = parseQueryParams(this.props.location.search);
        var tokenObject = JSON.parse(atob(paramObj.token.split('.')[1]));
        UserService.savePrivateSearchCriteria(tokenObject.employerId,tokenObject.vanpoolSearchCriteria)
        azureService.privateVanpoolSignup(paramObj.token);  
      } 
  
      if(this.props.location.pathname.startsWith("/userid=")) {
          var paramObj = parseQueryParams(this.props.location.pathname);
          var isTraditional = paramObj.isTraditionalMatch && paramObj.isTraditionalMatch.toLowerCase() === 'true' || paramObj.isTraditionalMatch == true;
          var isPrivate = paramObj.private && paramObj.private.toLowerCase() === 'true' || paramObj.private == true;
          if(isTraditional && isPrivate){
            azureService.TraditionalPrivateSignup(paramObj.userid,paramObj.id_token_hint); 
          }else if(isTraditional){
            azureService.letsRideTogether(paramObj.userid); 
          }else if(isPrivate){
            azureService.privateSignUp(paramObj.userid,paramObj.id_token_hint);  
          }else {
            azureService.signUp(paramObj.userid); 
          }  
      }
  
      if(!this.props.authenticated && this.props.location.pathname.startsWith('/signup')){
        var paramObj = parseQueryParams(this.props.location.search);
        if(paramObj.private && paramObj.private.toLowerCase() === 'true' || paramObj.private == true){
          azureService.privateSignUp(paramObj.userid,paramObj.id_token_hint);  
        }else {
          paramObj.userid ? azureService.signUp(paramObj.userid) :  azureService.signUp();
        }
      }
  
      if(!this.props.authenticated && !this.props.location.pathname.startsWith('/state=') && !this.props.location.pathname.startsWith('/signup') &&  !this.props.location.pathname.startsWith('/error=') && !config.localLogin && !this.props.location.pathname.includes('/phonePasswordReset') && !this.props.location.pathname.startsWith("/userid=") && !this.props.location.pathname.startsWith('/emailverification') && !this.props.location.pathname.startsWith('/joinavanpool')) {
        console.log("redirecting to login page from msal")
        var guid = parseQueryParams(this.props.location.search);
        guid.userid ? azureService.signIn(guid) :  azureService.signIn();
      }

      if (this.props.authenticated) {
        console.log("init")
        this.props.init();
      }

    }
    this.props.updateHistory(this.props.location);
  }

  componentWillReceiveProps(props) {
    if (props.authenticated && !props.profile) {
      this.props.init();
    }
  }

  componentWillUnmount() {
    this.state.unlisten();
    clearInterval(this.interval);
  }

  handleClosePopup()  {
    localStorage.setItem('mobileBrowser', true);
  // localStorage.setItem('PreferedMobile', true);
    window.location.reload(this.props.location);
  };

  render() {

    if (this.props.apiErrorOccurred) {
      return (
        <div className="app">
          <MetaTags>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          </MetaTags>
          <Header />
          <Banner />
          <div className="main">
            <Switch>
              <Route path="/error" component={ErrorScreen} />
            </Switch>
          </div>
          <Footer />
        </div>
      );
    }
    if(this.isMobileDevice && !this.continueFlow) {
      if(window.location.hash == '#/trip-recording/nonQrChecking' || window.location.hash == '#/trip-recording'){
        localStorage.setItem('mobileBrowser', true);
        window.location.reload(this.props.location);
      }else{
        return <MobileAlert onClose={ () => this.handleClosePopup()} isMobile={this.isMobileDevice} />;
      }
    }

    const { authenticated, profile, error, showBackdrop } = this.props;
    if(this.props.location.pathname.startsWith('/error=')){

      if(this.props.location.pathname.indexOf('AADB2C99001') >= 0 ) {
        window.location.href = '/#/login?error=USER_ALREADY_EXITS';
      }
      if(this.props.location.pathname.indexOf('AADB2C90118') >= 0  ||  this.props.location.pathname.indexOf('AADB2C90091') >= 0 ){
          return false
      }
      UserService.clearAuthentication();
      return <Error />;
    }
    if (error) {
      const userInfo = UserService.getAuthentication();
      let data = {
        profileId: userInfo ? userInfo.profileId : ''
      };
      if(userInfo && userInfo.accessToken) {
        logger.serverLog({
          url: error.url ? error.url : '',
          data: data,
          message: 'Commute Technical Error : Authorization Error',
          error: error.stack ? error.stack : error.message
        });
      }
      UserService.clearAuthentication();
      analyticsService.analyticsEvent({ type: 'Login Error', error: error.stack ? error.stack : error.message, api: error.url ? error.url : "" });
      return (
        <div>
          <Banner root={true} />
          <Error />
        </div>
      );
    }

    if (authenticated && !profile) {
      return null;
    }

    const authorizedRoutes = routes.filter(route => route.authorized());
    const overrideRoute = authorizedRoutes.find(route =>  {
        return  route.override || ( route.byPass && route.byPass())
    });

    return (
      <div className="app">
        <MetaTags>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </MetaTags>
        <Header hideNavigation={!!overrideRoute} />
        <Banner hideNavigation={!!overrideRoute} />
        <div id="confirmation-popup"></div>
        <div className={showBackdrop ? 'backdrop' : ''}></div>
        <div className="main">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              path="/passwordReset"
              render={props => (
                <PasswordReset {...props} policy="B2C_1A_PasswordReset" />
              )}
            />
            <Route
              path="/phonePasswordReset"
              render={props => (
                <PasswordReset {...props} policy="B2C_1A_PhonePasswordReset" />
              )}
            />
            {overrideRoute ? (
              <PrivateRoute {...overrideRoute} />
            ) : (
              authorizedRoutes.map(route => (
                <PrivateRoute key={route.path} {...route} />
              ))
            )}
            <Redirect
              to={
                overrideRoute
                  ? overrideRoute.path
                  : loginRedirectService.getRedirectUrl(
                      profile,
                      window.location.hash
                    )
              }
            />
          </Switch>
        </div>
        <div className="push">&nbsp;</div>
        <Footer />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    profile: state.userAuth,
    error: state.loginState.error,
    authenticated: state.loginState.hasToken && state.loginState.hasAccount,
    showBackdrop: state.navigation.showBackdrop,
    apiErrorOccurred: state.navigation.apiErrorOccurred
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateHistory(location) {
      dispatch({ type: actionTypes.URL_CHANGED, data: { location } });
    },
    init() {
      dispatch({ type: actionTypes.AUTHORIZATION_REQUEST });
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
