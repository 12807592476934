import UserService from '../shared/user-service';

const RegistrationSecurityProvider = {};

RegistrationSecurityProvider.isAuthorized = () => {
  const userProfile = UserService.getUserProfile();
  if(UserService.isAuthenticated() && userProfile && !userProfile.registrationComplete && !userProfile.isLead && !userProfile.isPrivateVanpoolSearch && !userProfile.inviteFlow && !userProfile.inviteId){
    return true;
  } else {
    return false
  }
};

RegistrationSecurityProvider.isOverride = () =>  {
  const userProfile = UserService.getUserProfile();
  if( userProfile.inviteFlow  || userProfile.isPrivateVanpoolSearch  || userProfile.isLead ) {
    return false;
  }else {
    return true;
  }
}

export default RegistrationSecurityProvider;