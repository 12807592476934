import { useEffect, useRef, useState } from 'react';
import dateUtility from '../../../shared/dateUtility';
import Highlight from '../../../_styles/images/Highlight.png';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function ChangeCommuteDaysConfirmation(props) {
  
  const [selectedDays, updateSelectedDays] = useState(props.selectedDays);
  const [startDateStr, updateStartDateStr] = useState(props.startDate);

  useEffect(() => {
    if(props.startDate){
      updateStartDateStr(props.startDate);
    }
    if(props.selectedDays){
      updateSelectedDays(props.selectedDays)
    }
  }, [props.startDate, props.selectedDays])

  useEffect(() => {
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": "change my commute days complete modal"  
        }
    });
  }, [])

  function doneClicked(){
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": 'done',
        "event_detail": {
            "modal": "change my commute days complete modal"
          }  
      }
    });
    props.close();
  }


  return (
    <div className="charges-popup show" id='change-commute-days-popup' aria-live='polite' role='alert' aria-modal='true'>
        <div className="card-operations-container confirmation">
          <div className='confirmation-container'>
            <div className="success-icon">
              <img className="successIcon" src={Highlight} aria-hidden='true'/>
            </div>
            <div className='text'>
              Your commute days have been updated.<br />
              Your new commute days are:
            </div>
            <div className='commute-days'>
              {selectedDays && selectedDays.map(day => {
                return <span className='block' aria-label={day} key={day}>{day.substring(0, 3)}</span>
              })}
            </div>
            <div className='text'>Your new weekly schedule will start on <span className='bolded'>{startDateStr}<span aria-hidden='true'>.</span></span></div>
            <button className='done-button' onClick={doneClicked}>Done</button>
          </div>
        </div>
    </div>
  );
}
