import UserService from '../../shared/user-service';

export default {
  isAuthorized: () => {
    const userProfile = UserService.getUserProfile();

    return userProfile && userProfile.isInVanpool && !userProfile.isDriver;
  },
  byPass: () => {
    const userProfile = UserService.getUserProfile();
    return userProfile && userProfile.isInVanpool && !userProfile.isDriver && userProfile.isPendingDriver;
  }
};
