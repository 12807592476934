import { createPermissions, getProfilePermissions, getSubscriptionPermissions } from '../shared/permissions/permissions';
import { createRole, getTripType } from './analyticsUserItemCreationService';

const profileService = {};

profileService.generateProfile = loginProfile => {
  return {
    firstName: loginProfile.firstName,
    lastName: loginProfile.lastName,
    coordinatorStatus: loginProfile.coordinatorStatus,
    driverStatus: loginProfile.driverStatus,
    vanpoolStatus: loginProfile.vanpoolStatusCode,
    isPendingCoordinator:
      loginProfile.coordinatorStatus === 'Pending Application',
    isPendingDriver: loginProfile.driverStatus === 'Pending Application',
    isPendingDriverApproval: loginProfile.driverStatus === 'Pending Approval',
    isInVanpool: loginProfile.vanpoolId !== '',
    isDriver: !!loginProfile.roles.driver,
    isAccountDashboardUser: !!loginProfile.roles.accountDashboardUser,
    isPartner: loginProfile.registration.isPartner,
    hasPendingContract: loginProfile.newContracts.length > 0,
    contracts: loginProfile.newContracts,
    // hasPendingContract: false,
    // contracts: [],
    permissions: createPermissions(loginProfile),
    roles: createRole(loginProfile),
    tripType: getTripType(loginProfile),
    registrationComplete:
      loginProfile.registration.registrationStatus === 'Complete',
    linkVanpool: loginProfile.registration.isVanpoolValidationRequired,
    employer: loginProfile.registration.companyName || '',
    phone: loginProfile.registration.phone,
    vanpoolId: loginProfile.vanpoolId || null,
    participantId: loginProfile.participantId || null,
    termsAndConditions: false,
    isRecentLeadSubmitted: loginProfile.isRecentLeadSubmitted,
    isFederatedLogin: loginProfile.isFederatedLogin,
    isSubscription: loginProfile.isSubscription,
    inviteFlow: loginProfile.inviteId ? true : false,
    inviteId: loginProfile.inviteId
  };
};

profileService.addSubscriptionPermissions = (profile, subscriptionAPIInfo) => {
  const subscriptionPermission = getSubscriptionPermissions(subscriptionAPIInfo);
  return {
    ...profile,
    permissions: [...profile.permissions, ...subscriptionPermission
    ]
  }
}

profileService.addProfilePermissions = (profile, profileData) => {
  const profilePermissions = getProfilePermissions(profileData);
  return {
    ...profile,
    permissions: [...profile.permissions, ...profilePermissions]
  }
}

export default profileService;
