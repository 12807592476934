import Payment from './payment/Payment';
import VanpoolRoute from './vanpool-route/Route';
import RosterRoute from './vanpool-roster/RosterRoutes';
import Vanpool from './vanpool/Vanpool';
import VehicleApplicationSecurityProvider from './vanpool-vehicle/VehicleApplicationSecurityProvider';
import Profile from './profile/Profile';
import Stats from './stats/Stats';
import DriverApplication from './applications/driver-application/DriverApplication';
import DriverApplicationSecurityProvider from './applications/driver-application/security-provder';
import CoordinatorApplication from './applications/coordinator-application/CoordinatorApplication';
import CoordinatorApplicationSecurityProvider from './applications/coordinator-application/security-provider';
import TripRecording from './trip-recording/TripRecording';
import Contract from './contracts/Contract';
import Registration from './registration/Registration';
import ContractApplicationSecurityProvider from './contracts/security-provider';
import RosterApplicationSecurityProvider from './vanpool-roster/RosterApplicationProvider';
import RouteApplicationSecurityProvider from './vanpool-route/RouteApplicationSecurityProvider';
import PaymentApplicationSecurityProvider from './payment/PaymentApplicationSecurityProvider';
import RegistrationSecurityProvider from './registration/RegistrationSecurityProvider';
import TripRecordingApplicationSecurityProvider from './trip-recording/TripRecordingApplicationSecurityProvider';
import LinkVanpool, {
  LinkVanpoolSecurityProvider
} from './registration/LinkVanpool';
import StatsApplicationSecurityProvider from './stats/StatsApplicationSecurityProvider';
import DashboardApplicationSecurityProvider from './dashboard/DashboardApplicationProvider';
import JoinCommuteRoute from './join-commute/JoinCommuteRoute';
import ProfileRoutes from './profile/components/ProfileRoutes';
import Agreement  from './agreement/Agreement'
import NewDashboard from './dashboard/NewDashboard';
import DriverScheduleRoute from './driver-schedule/DriverScheduleRoute';
import ErrorScreen from './shared/components/error-screen/ErrorScreen';
import ApplicationRoute from './applications/applicationRoute';

const routes = [
  { component: Profile, path: '/profile', authorized: () => true },
  { component: ProfileRoutes, path: '/myprofile', authorized: () => true },
  {
    component: Payment,
    path: '/payment',
    authorized: PaymentApplicationSecurityProvider.isAuthorized
  },
  {
    component: RosterRoute,
    path: '/roster',
    authorized: RosterApplicationSecurityProvider.isAuthorized
  },
  {
    component: VanpoolRoute,
    path: '/route',
    authorized: RouteApplicationSecurityProvider.isAuthorized
  },
  // {
  //   component: VanpoolVehicle,
  //   path: '/vanpool',
  //   authorized: VehicleApplicationSecurityProvider.isAuthorized
  // },
  {
    component: Vanpool,
    path: '/vanpool',
    authorized: VehicleApplicationSecurityProvider.isAuthorized
  },
  {
    component: NewDashboard,
    path: '/dashboard',
    authorized: DashboardApplicationSecurityProvider.isAuthorized
  },
  {
    component: Stats,
    path: '/stats',
    authorized: StatsApplicationSecurityProvider.isAuthorized
  },
  {
    component: ApplicationRoute,
    path: '/driver-application',
    authorized: DriverApplicationSecurityProvider.isAuthorized,
    byPass: DriverApplicationSecurityProvider.byPass
  },
  {
    component: TripRecording,
    path: '/trip-recording',
    authorized: TripRecordingApplicationSecurityProvider.isAuthorized
  },
  {
    component: LinkVanpool,
    path: '/link-vanpool',
    authorized: LinkVanpoolSecurityProvider.isAuthorized
  },

  {
    component: Registration,
    path: '/registration',
    authorized: RegistrationSecurityProvider.isAuthorized,
    override: RegistrationSecurityProvider.isOverride
  },
  {
    component: ApplicationRoute,
    path: '/coordinator-application',
    authorized: CoordinatorApplicationSecurityProvider.isAuthorized,
    override: true
  },
  {
    component: Contract,
    path: '/contract',
    authorized: ContractApplicationSecurityProvider.isAuthorized,
    override: true
  },
  {
    component: Agreement,
    path: '/agreement',
   // authorized: AgreementApplicationSecurityProvider.isAuthorized
   authorized:  () => true
  },
  {
    component: JoinCommuteRoute,
    path: '/JoinCommute',
    authorized:  () => true
  },
  {
    component: DriverScheduleRoute,
    path: '/driver-schedule',
    authorized:  () => true
  },
  {
    component: ErrorScreen,
    path: '/error',
    authorized: () => true,
  }
];

routes.default = '/dashboard';

export default routes;
