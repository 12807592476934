import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../appConfig';
import logoutService from '../../login/logoutService';
import ProfileImage from '../profile-image';
import LogoutIcon from '../../_styles/images/new-icons/logout-icon.svg'
import ProfileIcon from '../../_styles/images/new-icons/profile-icon.svg'
import HelpIcon from '../../_styles/images/new-icons/help-icon.svg'
import analyticsService from '../analytics/analytics-service';
import PlayButton from '../../_styles/images/PlayButton.png';

class TopNav extends React.Component {
  callClearAuthentication(e) {
    this.sendAnalyticEvent('logout');
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    logoutService.logout();
  }

  sendAnalyticEvent(str) {
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": `navigation:profile menu:${str}`
      }
    });
  }

   onKeyDown = (event) => {
    const menu = document.getElementById('profile-popup');
    const menuItems = Array.from(menu.querySelectorAll('[role="menuitem"]'));
    const currentIndex = menuItems.indexOf(document.activeElement);
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextIndex = (currentIndex + 1) % menuItems.length;
      menuItems[nextIndex].focus();
    }

    else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevIndex = (currentIndex - 1 + menuItems.length) % menuItems.length;
      menuItems[prevIndex].focus();
    }

   else if (event.key === 'Enter') {
      event.preventDefault();
      document.activeElement.lastChild.click();
    }
  }

  render() {
    const { classForProfile, override: hideNavigation, firstName, lastName, company, profileImage, isDriver, isSubscriptionUser,inviteFlow } = this.props;
    let name = !firstName && !lastName ? 'N/A' : `${firstName} ${lastName}`;
    const disableProfileClick =  inviteFlow ? true : false;
    return (
      <ul className={`top-nav-popup ${classForProfile} arrow-top`} id="profile-popup" role='menu' onKeyDown={this.onKeyDown}>
        <div className="top-nav-popup-wrapper">
          <div className='profile-info' role='group'>
            <div
              className='user-image static'
              role='presentation'>
              <ProfileImage profileImage={profileImage}>{`${name}`}</ProfileImage>
            </div>
            <div className='name-info'>
              <div className={`name ${classForProfile}`}>{`${name}`}</div>
              <div className={`company ${classForProfile}`}>{`${company}`}</div>
            </div>
          </div>
          <hr className='line' />
          {!hideNavigation ? (
            <li role="menuitem" tabIndex={0}>
              <img className='icon' src={HelpIcon} aria-hidden='true'/>
              <a
                className="upper-nav help-link"
                href={config.helpUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => this.sendAnalyticEvent('help')}
              >
                Help
              </a>
            </li>
          ) : null}
          {!hideNavigation ? (
              <li role="menuitem" tabIndex={0}>
                <img className='icon marketing-link-icon' src={PlayButton} aria-hidden='true'/>
                <a
                className="upper-nav marketing-link"
                href={`https://www.commutewithenterprise.com/en/${isDriver ? "driver": "rider"}/101-${isSubscriptionUser ? "flex" : "everyday"}-${isDriver ? "driver": "rider"}.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
              { isDriver ? "Watch Driver Best Practices" : "Watch Rider Best Practices" }
              </a>
              </li>
          ) : null}
          {!hideNavigation ? (
            <>
              <li role="menuitem" tabIndex={0}>
                <img className='icon' src={ProfileIcon} aria-hidden='true'/>
                <Link
                  className="upper-nav profile-link"
                  replace={'#/myprofile' === window.location.hash}
                 // to="/myprofile"
                  to= {disableProfileClick ? "#" : "/myprofile"}
                  onClick={() => this.sendAnalyticEvent('my profile')}
                  >
                  My Profile
                </Link>
              </li>
            </>

          ) : null}
          <hr className='line' aria-hidden='true'/>
          <li role="menuitem" tabIndex={0}>
            <button
              className="upper-nav sign-out"
              id="sign-out-button"
              onClick={this.callClearAuthentication.bind(this)}
            >
              <img className='icon' src={LogoutIcon} aria-hidden='true' />Log&nbsp;Out
            </button>
          </li>
        </div>
      </ul>
    );
  }
}

export default TopNav;