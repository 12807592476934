import * as CalendarHelpers from '../../shared/inputs/Calendar/Calendar';

import config from '../../appConfig';
import creditCardService from '../../shared/credit-card/creditCardService';
import dateUtility from '../../shared/dateUtility';

export const weekdays_lower = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const weekdays_upper = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const check2hoursApart = (time1, time2) => {
  const diff = dateUtility.getDuration(`${time1.value}:00`, `${time2.value}:00`)
  return diff >= 120;
}


export const convertFirstLetterInArray = (arr) => {
  return arr.map(day => day.charAt(0).toLowerCase() + day.slice(1))
};

function getHourNumber(hour) {
  return hour == 0 ? `12` : hour > 12 ? `${hour - 12}` : `${hour}`
}

export function getTimeObjectFromString(str) {
  const splits = str.split(':');
  const hourNumber = parseInt(splits[0]);
  const day = hourNumber > 12 ? 'PM' : 'AM';

  return {
    hour: getHourNumber(hourNumber),
    minutes: splits[1],
    day,
    value: `${splits[0]}:${splits[1]}`
  }
}

export function getUpdatedRoosterDays(roosterArr, isUpper = false) {
  let days;
  if (isUpper) {
    days = { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false }
  } else {
    days = { "monday": false, "tuesday": false, "wednesday": false, "thursday": false, "friday": false, "saturday": false, "sunday": false }
  }
  if (roosterArr && roosterArr.length > 0) {
    roosterArr.forEach((day) => {
      days[day] = true;
    })
  }
  return days;
}

export function getTimeFromArrival(time) {
  if (time && time !== 'N/A') {
    return getTimeObjectFromString(time);
  }
  return {};
}

export function getTimeIntervals() {
  var timeIntervals = [];
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < 4; j++) {
      var minutes;
      var hour;
      var day = 'PM';
      if (i < 12) {
        day = 'AM';
      }
      {
        minutes = 15 * j < 10 ? '00' : `${15 * j}`;
        hour = i == 0 ? `12` : i > 12 ? `${i - 12}` : `${i}`;
        timeIntervals.push({
          hour,
          minutes,
          day,
          value: `${i}:${minutes}`,
        });
      }
    }
  }
  return timeIntervals;
}

export const flexibilityTimeIntervals = [{
  text: 'None',
  flexibility: 0
},
{
  text: '10 min',
  flexibility: 10
},
{
  text: '20 min',
  flexibility: 20
},
{
  text: '30 min',
  flexibility: 30
},
{
  text: '40 min',
  flexibility: 40
},
{
  text: '50 min',
  flexibility: 50
},
{
  text: '60 min',
  flexibility: 60
}];

export function getIntervalFromFlexibility(flexibility) {
  return flexibilityTimeIntervals.find(flex => flex.flexibility === flexibility);
}

export function getUserPackage(noOfDays) {
  switch (noOfDays) {
    case 1:
      return '2X';
    case 2:
      return '2X';
    case 3:
      return '3X';
    case 4:
      return '4X';
    case 5:
      return 'Unlimited';
    default:
      return 'Unlimited';
  }
}

export function getPlan(days) {
  if(days == 1 || days == 2) {
    return 2;
  } else {
    return days;
  }
}

export function isValidEmail(email) {
  if (!email) {
    return true;
  }
  return /@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function getDaysFromRosterDays(rosterDays) {
  let arr = [];
  arr = rosterDays.map(day => weekdays_lower.indexOf(day.toLowerCase()));
  return arr;
}


export function getVanAvailableDays(availableObject) {
  let availableDays = [];
  if (availableObject) {
    weekdays_upper.map((weekday) => {
      if (availableObject[weekday] && availableObject[weekday].available)
        availableDays.push(weekday);
    })
  }
  return availableDays;
}

export function getVanOperationalDays(availableObject) {
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  let availableDays = [];
  if (availableObject) {
    weekdays.map((weekday) => {
      if (availableObject[weekday] && availableObject[weekday].operational)
        availableDays.push(weekday);
    })
  }
  return availableDays;
}

export function addDays(days, date) {
  let newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() + days);
  return newDate;
}

export function getLastDayOfTwoMonthsLater(date) {
  const givenDate = new Date(date);
  const year = givenDate.getFullYear();
  const month = givenDate.getMonth() + 2;
  return new Date(year, month + 1, 0);
}

export const MONTHS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

export const getNextYears = () => {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(10), (v, idx) => year + idx);
  return years;
}

export const getRandomColor = () => {
  const arr = ['#a17827', '#4a90e2', '#c96d1f', ' #6fc099'];
  return arr[Math.floor(Math.random() * arr.length)];
}


export const CARD_COMPANIES = [
  {
    type: 'mastercard',
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    length: [16],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/Master-Card.png`
  },
  {
    type: 'visa',
    pattern: /^4/,
    length: [13, 16, 19],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/visa.svg`
  },
  {
    type: 'discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    length: [16],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/discover.png`
  },
  {
    type: 'amex',
    pattern: /^(34|37)/,
    length: [15],
    luhn: true,
    cvcLength: 4,
    imgSrc: `${config.imagePath}/Amexcard.png`
  },
  {
    type: '',
    pattern: /.*/,
    length: [16],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/DefaultCard.png`
  }
];

export function getCardInfoFromBrand(brand) {
  if (brand) {
    const card = CARD_COMPANIES.find((card) => (card.type).toLowerCase() === brand.toLowerCase());
    return card || creditCardService.getCardTypeJoinCommute('');
  } else {
    return creditCardService.getCardTypeJoinCommute('');
  }
}

export const initDate = {
  month: MONTHS[new Date().getMonth()],
  year: new Date().getFullYear()
}

export const getFullDate = (dateObj) => {
  return {
    day: dateObj.getDate(),
    month: CalendarHelpers.MONTHS[dateObj.getMonth()],
    year: dateObj.getFullYear(),
    monthNumber: dateObj.getMonth(),
    date: dateObj
  }
}

export function numberWithCommas(x) {
  if (x) {
    if(`${x}`.includes('.')){
      const splits = `${x}`.split('.');
      return `${numberWithCommas(splits[0])}.${splits[1]}`
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return '0';
}

export function getCommonDays(operational, userPreferred) {
  let commonDays = [];
  operational.map((day) => {
    if (userPreferred[day])
      commonDays.push(day);
  })
  return commonDays;
}

export function make2DigitNumber(num) {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export function getPreferredDays(obj) {
  let days = [];
  weekdays_upper.map(day => {
    if (obj[day.toLowerCase()]) {
      days.push(day);
    }
  });
  return days;
}

export function findObjectIndexByPropertyValue(array, property, value) {
  let indexVal = array.findIndex((obj) => obj[property] === value);
  if(indexVal >= 0){
    return indexVal
  }
  return -1;
}

export function findNextRideDay(rideDays, currentDay){
  const currentIndex = weekdays_upper.indexOf(currentDay);
  const nextRideDay = weekdays_upper
  .slice(currentIndex + 1)
  .concat(weekdays_upper.slice(0, currentIndex + 1))
  .find(day => rideDays.includes(day));

  return nextRideDay;
}

export function findNextElement(array, currentIndex) {
  // Validate input
  if (!Array.isArray(array)) {
    throw new Error("The first argument must be an array.");
  }
  if (currentIndex < 0) {
    return null; // No next element if index is out of bounds or at the last element
  }
  if(currentIndex == (array.length-1)){
    return array[0]
  }
  return array[currentIndex + 1];
}